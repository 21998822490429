/* eslint-disable react-hooks/exhaustive-deps */
import { InputGroup, Button, Dropdown, Form } from "react-bootstrap";
import { FilterCollapseIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useMemo, useState, Fragment } from "react";

import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon } from "../Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import useDebounce, {
  useChosenBackendUrl,
  useEffectOnce,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { backendApis } from "../../config";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { compareAsc, format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  convertMomentDate,
  fetchActionsUtil,
  formatDate,
  paginationOptions,
  scrollToTop,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";

import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";

import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ItemsTable from "../utils/ItemsTable";
import TableComponent from "../TableComponent";
import ConvertQuantity from "../utils/ConvertQuantity";
import ModalLoader from "../utils/ModalLoader";
import GRNDialog from "../GRNDialog";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import CurrencyInput from "react-currency-input-field";
import Datetime from "react-datetime";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";

const CompanySwitcher = () => {
  const backendUrl = useChosenBackendUrl();

  const options = backendApis
    .map((el) => ({
      ...el,
      value: el.name,
      label: el.name,
    }))
    .filter((el) => el?.isHq);
  // .filter((el) => el.value !== authUser.company);

  const setSelectedCompanyForAnalytics = useStoreActions(
    (actions) => actions.setSelectedCompanyForAnalytics
  );
  const selectedCompanyForAnalytics = useStoreState(
    (state) => state.selectedCompanyForAnalytics
  );
  const selectedCompany = useMemo(() => {
    return options.find((el) =>
      selectedCompanyForAnalytics?.value
        ? el.value === selectedCompanyForAnalytics?.value
        : el.url === backendUrl
    );
  }, [options, backendUrl, selectedCompanyForAnalytics]);

  useEffectOnce(() => {
    setSelectedCompanyForAnalytics(options[0]);
  });

  return (
    <div
      className="d-flex gap-1 align-items-center"
      style={{ marginTop: "-0.25rem" }}
    >
      <Dropdown style={{ margin: 0 }}>
        <Dropdown.Toggle
          variant=""
          className="bg-light-blue text-primary pe-2"
          bsPrefix="change"
        >
          {selectedCompany?.value}
          {"    "}
          <span className="d-inline  me-n2">
            <ChevronDownIcon />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu
          popperConfig={{
            strategy: "fixed",
          }}
          renderOnMount
          className=""
        >
          {options.map((el, index) => (
            <Dropdown.Item
              key={index}
              as="button"
              className={`${
                el.value === selectedCompany?.value ? "active" : ""
              }`}
              onClick={() => setSelectedCompanyForAnalytics({ ...el })}
            >
              {el.value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default function ItemsExpectedReport() {
  const { user: authUser, backendUrl: currentBackendUrl } = useAuth();
  const backendUrl = useChosenBackendUrl();

  const mainBackendUrl = useAuth();

  const generalSettings = useStoreState((state) => state.generalSettings);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  useScrollTop();

  const [dateIndex, setDateIndex] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [allStorage, setAllStorage] = useState([]);

  const initialFilterParams = useMemo(() => {
    try {
      return {
        page: 1,
        limit: 40,
        barcode: "",
        itemName: "",
        issueTo: authUser.company,
        status: "Approve",
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  }, [generalSettings]);

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const [filterData, setFilterData] = useState([]);

  const [datePopoverOpened, setDatePopoverOpened] = useState(false);

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((query) => ({ ...query, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const fetchItems = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items/expected-items?${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { storage } = await fetchActionsUtil(
      `${backendUrl}/api/items/storage-location`,
      "GET"
    );

    const { data } = await response.json();

    data.storage = storage?.map((el) => ({
      value: el.storageID,
      label: el.name,
    }));

    return data;
  };

  const {
    data = { count: 0, items: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["queryActions.ITEMS_OUT_OF_STOCK", queryParams],
    () => fetchItems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    const element = data?.items?.map((el) => ({
      ...el,
      mainQty: el.quantity,
      ExpireDate: el.ExpireDate ? new Date(el.ExpireDate) : "",
      Batch_Name: el.Batch_Name ? el.Batch_Name : "",
      StorageLocation: el.StorageLocation ? el?.StorageLocation : "",
    }));
    const filtered = data?.items?.filter(
      (el) =>
        // parseInt(el.remaining) !== 0 &&
        // parseInt(el?.mainQty) - parseInt(el?.remaining) > 0
        parseFloat(el.remaining) !== 0
    );

    setFilterData(filtered);
    setTableData(element);
  }, [data?.items]);

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const onSelected = (item) => {
    const theSelected = [...selectedData];
    if (theSelected.length > 0) {
      const index = theSelected.findIndex((it) => it.id === item.id);

      if (index >= 0) {
        theSelected.splice(index, 1);
        setSelectedData(theSelected);
      } else {
        setSelectedData((d) => [...d, item]);
      }
    } else {
      setSelectedData((d) => [...d, item]);
    }
  };

  const selectAll = (e) => {
    if (!e.target.checked) {
      setSelectedData([]);
    } else {
      const element = tableData?.filter(
        (el) =>
          // parseInt(el.remaining) !== 0 &&
          // parseInt(el?.mainQty) - parseInt(el?.remaining) > 0
          parseFloat(el.remaining) !== 0
      );
      setSelectedData(element);
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const printGRN = async (InvoiceNo) => {
    await GRNDialog({
      title: "Transaction posted successfully",
      // description: "...",
      InvoiceNo,
    });
  };

  const printGRNWarehouse = async (InvoiceNo) => {
    await GRNDialog({
      title: "Transaction posted successfully in Warehouse",
      // description: "...",
      InvoiceNo,
      useAPI: `${backendUrl}/api/warehouse/pdf/grn`,
    });
  };

  const ReceiveMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/items/sender-end-items`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({}) => {
        toast.success(`Success`);
        refetch();
        setSelectedData([]);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const rowFunction = (index) => {
    setDateIndex(index);
  };

  const fechWareHouse = async (query) => {
    const data = await fetchActionsUtil(
      `${currentBackendUrl}/api/items/storage-location?${queryString.stringify(
        query
      )}`,
      "GET"
    );

    data.storage = data.storage?.map((el) => ({
      ...el,
      value: el.storageID,
      label: el.name,
    }));
    setAllStorage(() => data?.storage);
    return data;
  };

  useQuery(
    ["FETCH_STORAGE-LOCATION-ITEMS", queryParams],
    () => fechWareHouse(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // The main table data 158a37e51fc62ad3
  const tableBodyData = (el, index) => {
    const color =
      compareAsc(new Date(), new Date(el?.ExpireDate)) === 1
        ? { borderLeft: "5px solid red" }
        : { borderLeft: "5px solid inherit" };

    const filled = parseFloat(el.remaining) === 0;

    return (
      <Fragment>
        {/* <td style={color} key={el?.Bar_Code}>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            />
          </Dropdown>
        </td> */}
        <td style={color} key={el?.Bar_Code}>
          <input
            type="checkbox"
            onChange={() => onSelected(el)}
            checked={Boolean(selectedData.find((ek) => ek.id === el.id))}
            disabled={
              // parseInt(el.remaining) === 0 ||
              // parseInt(el?.mainQty) - parseInt(el?.remaining) <= 0
              filled
            }
          />
        </td>
        <td>{data?.startIndex + index + 1}</td>
        <td className={filled ? "text-muted" : ""}>{el?.POId}</td>
        <td className={filled ? "text-muted" : ""}>{el?.barcode}</td>
        <td className={filled ? "text-muted" : ""}>{el?.itemName}</td>
        <td>
          <input
            type="text"
            name="Batch_Name"
            className="form-control border-0 px-1"
            value={el?.Batch_Name}
            onChange={(e) =>
              handleInputChange({
                index,
                name: "Batch_Name",
                value: e.target.value,
              })
            }
            disabled={filled}
          />
        </td>
        <td>
          <Popover
            reposition={false}
            isOpen={index === dateIndex && datePopoverOpened}
            onClickOutside={() => {
              index === dateIndex && setDatePopoverOpened(false);
            }}
            align="end"
            padding={10}
            content={() => (
              <Datetime
                dateFormat={filled ? "" : "MM DD, YYYY"}
                className="date-picker-2"
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                name="ExpireDate"
                inputProps={{
                  className: `date-input form-control`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                input={false}
                value={el.ExpireDate}
                onChange={(date) => {
                  handleInputChange({
                    index,
                    name: "ExpireDate",
                    value: date,
                  });
                  setDatePopoverOpened(false);
                }}
              />
            )}
          >
            <DatePickerCustomInput
              onClick={() => setDatePopoverOpened(!datePopoverOpened)}
              value={convertMomentDate(el.ExpireDate)}
            />
          </Popover>
        </td>
        <td className="text-black">
          <div style={{ zIndex: 10 }}>
            <Select
              classNamePrefix={`form-select`}
              options={allStorage}
              value={allStorage?.find((ek) => ek.value === el.StorageLocation)}
              onChange={({ value }) => {
                handleInputChange({
                  index,
                  name: "StorageLocation",
                  value,
                });
              }}
              menuPosition="fixed"
              isDisabled={filled}
            />
          </div>
        </td>
        <td>{el?.warehouseName || ""}</td>
        <td>
          {parseFloat(el.remaining) === 0 ? (
            <ConvertQuantity quantity={el.quantity} desc={el?.Item_Desc} />
          ) : (
            <CurrencyInput
              className="form-control border-0 px-1 w-70"
              value={el.quantity}
              onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
              name="quantity"
              onValueChange={(value, name) =>
                handleInputChange({
                  index,
                  name,
                  value,
                })
              }
              //  disableGroupSeparators
              allowNegativeValue={false}
              allowDecimals={true}
              //   disabled={selectedItems}
              width={70}
              disabled={filled}
            />
          )}
        </td>
        <td className={filled ? "text-muted" : ""}>
          <ConvertQuantity
            quantity={currency(el?.mainQty).subtract(el?.remaining).value}
            desc={el?.Item_Desc}
          />
        </td>
        <td>
          <ConvertQuantity quantity={el.remaining} desc={el?.Item_Desc} />
        </td>
        <td className={filled ? "text-muted" : ""}>{el?.Sendercompany}</td>
        <td className={filled ? "text-muted" : ""}>{el?.receivingofficer}</td>
        <td className={filled ? "text-muted" : ""}>
          {el?.Date_Log ? formatDate(el?.Date_Log, "dd MMM, yyyy") : ""}
        </td>
      </Fragment>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          {/* <th /> */}
          <th>
            <input
              type="checkbox"
              onChange={selectAll}
              checked={Boolean(selectedData.length === filterData.length)}
            />
          </th>
          <th>S/N</th>
          <th>PO Number</th>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>Batch Name</th>
          <th>Expiry Date</th>
          <th>Storage Location</th>
          <th>Warehouse</th>
          <th>QTY</th>
          <th>Supplied QTY</th>
          <th>Remaining QTY</th>
          <th>Sent By</th>
          <th>Receiving Officer</th>
          <th>Date</th>
        </tr>
      </thead>
    );
  };

  const receiveItems = async () => {
    const uniquePO = [...new Set(selectedData.map((d) => d.POId))];
    // const warehouse = selectedData?.find((el) => el.warehouse !== "" && el.warehouse !== undefined && el.warehouse !== " ")
    const items = uniquePO?.map((el) => {
      const elements = selectedData?.filter((item) => item?.POId === el);

      return elements;
    });

    console.log(items);

    if (!selectedData.length > 0) {
      return toast.error(`Select an item`);
    }

    const errors = selectedData.filter((el) => el.quantity > el.remaining);
    if (errors.length > 0) {
      return toast.error(
        `Input a lower quantity for ${errors
          .map((el) => el.itemName)
          .join(",")}`
      );
    }

    const inValidDate = selectedData.filter((el) => el?.ExpireDate === "");

    if (inValidDate.length > 0) {
      return toast.error(
        `Add Expire Date for ${inValidDate.map((el) => el.itemName).join(",")}`
      );
    }

    const ele = {
      selectedData,
      sender: `${mainBackendUrl?.backendUrl}/api/items/receivers-end-items`,
    };
    // console.log(ele);
    if (
      await ConfirmDialog({
        title: "Receive Item(s)",
        description:
          "Are you sure you want to receive this Item(s) to your inventry",
      })
    ) {
      ReceiveMutation.mutate(ele);
    }
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>PO Number</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="POId"
                          placeholder="Enter PO number"
                          value={filterParams?.POId}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Code</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="barcode"
                          placeholder="Enter Item Code"
                          value={filterParams?.barcode}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <ItemsTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item Name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>
                    <hr className="mt-3 mb-4" />
                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Items Expected Report
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                    title="Refresh"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  <div>
                    <button className="btn btn-primary" onClick={receiveItems}>
                      {" "}
                      Receive Item
                    </button>
                  </div>
                  <CompanySwitcher />
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={tableData}
                  tableDataRowFunction={tableBodyData}
                  className="product-table text-nowrap"
                  getIndexFunction={rowFunction}
                />{" "}
                {!isFetching && isSuccess && isEmpty(tableData) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    scrollToTop();
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            </div>
          </div>
        </div>

        <ModalLoader show={ReceiveMutation.isLoading} />

        {/*  <QuantityConversionModal /> */}
      </main>
    </section>
  );
}
