import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  amountTypes,
  convertFromBaseCurrency,
  convertToBaseCurrency,
  copyText,
  customerFullName,
  formatDate,
  getNumberFromString,
  initialServiceItem,
  maxTopPopperConfig,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  resolveApprovalBadgeBg,
  tonsToPcs,
  toTonsOrPcs,
  Units,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  RecieptIcon,
  BookIcon,
  ShoppingCartIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty, lowerCase } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import {
  queryActions,
  reportActions,
  requisitionActions,
} from "../../utils/reactQueryActions";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import useDebounce, {
  useCurrencies,
  useEffectOnce,
  useIsAdmin,
  useIsSalesManager,
  useTaxOptions,
  useUpdateEffect,
} from "../../utils/hooks";
import AddRodItemModal from "./AddRodItemModal";
import ConfirmDialog from "../ConfirmDialogue";
import EditIronRodItemModal from "./EditIronRodItemModal";
import PermitModal from "./PermitModal";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import printJS from "print-js";
import { Link, useParams, useNavigate } from "react-router-dom";
import { first, cloneDeep, debounce } from "lodash";
import moment from "moment";

import { truncate } from "lodash";
import { ApproveAndCloseDialog, RejectDialog } from "../Requisition/Dialog";
import { last } from "lodash";
import ApproveAndSendModal from "../Requisition/ApproveAndSendModal";
import AsyncCreatableSelect from "react-select/async-creatable";
import ApprovalBadge from "../utils/ApprovalBadge";

export default function EditPendingServiceInvoice() {
  const navigate = useNavigate();
  let { TransactionID: TransactionIDFromParams } = useParams();
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();
  const isAdmin = useIsAdmin();
  const isSalesManager = useIsSalesManager();
  const paymentMethod = [
    {
      icon: <CreditMemoSelectIcon />,
      label: "Proforma",
      value: "Proforma",
    },
  ];

  const taxOptions = useTaxOptions();

  const saleTypes = Units;
  const [isLoading, setIsLoading] = useState(false);
  const generalSettings = useStoreState((state) => state.generalSettings);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const { backendUrl, user: authUser } = useAuth();
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showPermitModal, setShowPermitModal] = useState(false);

  //  const [tableData, setTableData] = useState([]);
  const [lockedTableData, setLockedTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);
  const [showApproveAndSendModal, setShowApproveAndSendModal] = useState(false);

  const convertQuantity = (Quantity, Item_Desc, type) => {
    return lowerCase(type) === "tons"
      ? pcsToTons(Quantity, Item_Desc, itemMeasurements)
      : Quantity;
  };

  const createRodInvoice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/create`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createRodInvoiceMutation = useMutation(
    (payload) => createRodInvoice(payload),
    {
      onSuccess: ({ data, message }) => {
        toast.success(message);
        formik.resetForm();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  // permit
  const createRodPermit = async (payload) => {
    if (
      payload.currency &&
      payload.currency !== generalSettings?.prevailingCurrency
    ) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }

    let response = await fetch(
      `${backendUrl}/api/invoice/edit-pending-permit`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createRodPermitMutation = useMutation(
    (payload) => createRodPermit(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );
  // ----------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      //  tax: "",
      taxType: "None",
      PaymentType: "Proforma",
      proformaNumber: "",
      splitPaymentType: "",
      printWaybill: true,
      salesDate: moment(),
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      sendEmail: false,
      printReciept: true,
      dueIn: 1,
      pendingTransaction: "",
      supplyNow: true,
      ShipTo: "",
      customerBalance: "",
      amountToPayFromCustomerCredit: "",
      shippingCost: 0,
      terms: [{ text: "" }],
      remark: "",
      otherCharges: 0,
      currency: "",
      linkedPaymentID: "",
      milestone: "",
      tableDataInForm: [initialServiceItem()],
      conversionAmount: 0,
      bankOnInvoice: "",
      generalDiscountType: "Fixed",
      generalDiscount: 0,
      generalDiscountPercentage: "",
    },
    validationSchema: yup.object().shape({
      PaymentType: yup.string().required(),
      // invoiceCat: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      if (isEmpty(tableData)) return toast.error(`Please add an Item`);
      if (!selectedCustomer) return toast.error(`Please select a customer`);

      // send to pending
      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
        })
      ) {
        createRodPermitMutation.mutate({
          items: tableData
            .map((el) => ({
              ...el,
              Quantity: el.Quantity,
            }))
            .filter((el) => el.Item_Name && el.Bar_Code),
          ...values,
          subTotal,
          discount,
          amountDue,
          profit,
          balance,
          amount,
          customer: selectedCustomer,
          taxValue,
          costOFSales,
          Status: tableData[0].Status,
          terms: JSON.stringify(values.terms),
          jobNumber: requisition?.jobNumber,
        });
      }
    },
    onReset: () => {
      // setTableData([]);
    },
  });

  const tableData = useMemo(() => {
    return cloneDeep(formik.values.tableDataInForm);
  }, [formik.values?.tableDataInForm]);

  const setTableData = (items) => {
    formik.setFieldValue("tableDataInForm", [...items]);
  };

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));
    data.banks = data?.banks
      .filter((el) => el?.BankName)
      .map((el) => ({
        ...el,
        label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : `(NGN)`}`,
        value: el.BankName,
      }));

    /* const chosenBankOnInvoice = data.banks.find(
      (el) => el.showOnSalesInvoice === "yes"
    );
    if (chosenBankOnInvoice && isEmpty(formik.values.bankOnInvoice)) {
      formik.setFieldValue("bankOnInvoice", chosenBankOnInvoice?.BankName);
    } */
    return data;
  };

  const { data = { invoiceCat: [], banks: [] } } = useQuery(
    [queryActions.INVOICE_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const populateTableFromItems = (items) => {
    // convert qtys and clean
    if (
      items &&
      items[0]?.baseCurrency !== generalSettings?.prevailingCurrency
    ) {
      items = items.map((item) =>
        convertFromBaseCurrency({
          data: item,
          conversionAmount: items[0]?.baseConversionAmount,
        })
      );
    }
    //

    items = items.map((el) => {
      // check if sales type
      const value = qtyFormat(el.QTY, el.Serial_Number, itemMeasurements).split(
        "-"
      );
      //  console.log(value);
      const tons = value[0];
      const pcss = value[1];
      const istype = value[0];

      let theSaleType = el.Serial_Number
        ? Number(tons) > 0
          ? "Tons"
          : "Pieces"
        : "Each";

      if (istype === "normal") {
        theSaleType = "Each";
      }

      // console.log(theSaleType);

      const discountPerItem = currency(el?.Discount || 0).divide(
        getNumberFromString(
          el?.Warrant_Duration ? el?.Warrant_Duration : el?.QTY ? el.QTY : 1
        )
      ).value;

      return {
        ...el,
        saleType:
          appSettings.requireSalesRep || appSettings.isBatchStandard
            ? el?.saleType
            : theSaleType,
        Item_Desc: el.Serial_Number,
        PriceSold: currency(el.PriceSold, {
          symbol: "",
          separator: "",
        }).format(),
        Quantity: currency(el.QTY, {
          symbol: "",
          separator: "",
        }).format(),
        UnitCost: currency(el.UnitCost, {
          symbol: "",
          separator: "",
        }).format(),
        Discount: currency(el.Discount, {
          symbol: "",
          separator: "",
        }).format(),
        SubTotal: currency(el.SubTotal, {
          symbol: "",
          separator: "",
        }).format(),
        Profit: currency(el.Profit, {
          symbol: "",
          separator: "",
        }).format(),
        UnitPrice: currency(el.Unit_Price, {
          symbol: "",
          separator: "",
        }).format(),
        discountPerItem,
      };
    });

    //console.log(items);

    if (items) {
      formik.resetForm();

      const {
        PayType,
        VAT,
        OverwriteOfficer,
        Date_Log,
        TransactionID,
        customer,
        ProductName,
        shippingCost,
        /*  currency: */ baseCurrency = generalSettings?.prevailingCurrency,
        otherCharges,
        terms,
        remark,
        taxType,
        ShipTo,
        linkedPaymentID,
        milestone,
        /*  conversionAmount:  */ baseConversionAmount = 0,
        bankOnInvoice,
        generalDiscount,
        generalDiscountType,
        generalDiscountPercentage,
      } = items[0];
      formik.setValues({
        ...formik.values,
        taxValue: VAT,
        PaymentType: PayType,
        OverwriteOfficer,
        salesDate: Date_Log,
        pendingTransaction: TransactionID,
        invoiceCat: ProductName,
        shippingCost,
        currency: baseCurrency,
        otherCharges,
        terms: terms ? JSON.parse(terms) : [{ text: "" }],
        remark,
        taxType: taxType || "None",
        milestone,
        conversionAmount: baseConversionAmount,
        bankOnInvoice,
        generalDiscount,
        generalDiscountType,
        generalDiscountPercentage,
      });

      // This was not setting, had to delay
      setTimeout(() => {
        formik.setFieldValue("ShipTo", ShipTo);
        formik.setFieldValue("linkedPaymentID", linkedPaymentID);
      }, 500);

      setTableData(items);
      setSelectedCustomer(customer);

      // locked
      setLockedTableData(items);
    }
  };

  useEffect(() => {
    formik.setFieldValue(
      "ShipTo",
      selectedCustomer?.LastName
        ? selectedCustomer?.LastName
        : defaultCustomer?.LastName
    );
  }, [selectedCustomer]);

  const handleSelectedPermit = (permit) => {
    setShowPermitModal(false);
    populateTableFromItems(permit.items);
  };

  const handleAddItem = (item) => {
    // console.log(item);
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }

    // if sale rep version, ensure all other items have same product_name
    if (!isEmpty(tableData) && appSettings.requireSalesRep) {
      const firstItem = first(tableData);
      console.log(firstItem);
      if (firstItem.Product_Name !== item.Product_Name) {
        return toast.error(
          `Select Items with the same manufacturer (${
            firstItem.Product_Name
          }), `
        );
      }
    }
    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === item.Bar_Code
    );
    if (foundIndex === -1) {
      setTableData([...tableData, item]);
    } else {
      const oldItem = tableData[foundIndex];
      if (oldItem.saleType !== item.saleType) {
        setTableData([...tableData, item]);
      } else {
        tableData[foundIndex] = item;
        setTableData([...tableData]);
      }
    }

    formik.setFieldValue("invoiceCat", item.Product_Name);
    setShowItemSelectorModal(false);
  };

  const handleEditItem = (item) => {
    /* if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    } */
    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === item.Bar_Code
    );

    tableData[foundIndex] = item;
    setTableData([...tableData]);
    setEditedItemIndex(null);
  };

  const handleEditItemByIndex = (item, index) => {
    item.Profit = currency(item.PriceSold)
      .subtract(item.UnitCost)
      .multiply(item.Quantity).value;

    item.SubTotal = currency(item.PriceSold)
      .subtract(item.Discount)
      .multiply(item.Quantity).value;

    tableData[index] = item;
    setTableData([...tableData]);
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer(defaultCustomer);
  };

  const handleRowClick = (e, index) => {
    if (e.target.tagName === "TD") setEditedItemIndex(index);
  };

  // discount sum
  const discount = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => {
            return el.Discount;
            /*  currency(el.Discount, { symbol: "", separator: "" })
          .multiply(
            convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
          )
          .format() */
          })
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const subTotal = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                symbol: "",
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .add(discount)
          .format()
      : "0.00";
  }, [tableData, discount]);

  const chargesAfterTax = useMemo(() => {
    return currency(formik.values.loadingCharge, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.offloadingCharge)
      .add(formik.values.posCharge)
      .add(formik.values.transportCharge)
      .add(formik.values.shippingCost)
      .add(formik.values.otherCharges);
  }, [
    formik.values.offloadingCharge,
    formik.values.loadingCharge,
    formik.values.posCharge,
    formik.values.transportCharge,
    formik.values.shippingCost,
    formik.values.otherCharges,
  ]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);

    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .subtract(discount)
      .subtract(formik.values.generalDiscount)
      .multiply(taxType?.percentage)
      .format();
  }, [
    subTotal,
    discount,
    formik.values.taxType,
    taxOptions,
    formik.values.generalDiscount,
  ]);

  const amountDue = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;

    const total = sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .multiply(100)
          .divide(100)
          .add(taxValue)
          .add(chargesAfterTax)
          .format()
      : "0.00";

    const value = formik.values.PaymentType;
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    } else {
      formik.setFieldValue("amountPaid", total);
    }

    formik.setFieldValue("amountToPayFromCustomerCredit", total);
    formik.setFieldValue("cashAmount", total);
    return total;
  }, [tableData, taxValue, chargesAfterTax]);

  const amount = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.cashAmount)
      .format();
  }, [amountDue, formik.values.cashAmount]);

  const balance = useMemo(() => {
    // is Balance Zero for split Payment
    const totalCashForSplit = currency(formik.values.cashAmount, {
      symbol: "",
      separator: "",
    })
      .add(amount)
      .format();

    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(
        formik.values.PaymentType === "Split Payment"
          ? totalCashForSplit
          : formik.values.amountPaid
      )
      .format();
  }, [
    amount,
    amountDue,
    formik.values.amountPaid,
    formik.values.PaymentType,
    formik.values.cashAmount,
  ]);

  const profit = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.Profit)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const grandTotal = useMemo(() => {
    return amountDue;
  }, [amountDue]);

  const costOFSales = useMemo(() => {
    return tableData
      .map(
        (el) =>
          currency(el.UnitCost, { symbol: "", separator: "" }).multiply(
            convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
          ).value
      )
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [tableData]);

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("PaymentType", value);
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    }
  };

  const getRequisitionDetails = async (TransactionIDFromParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/requisition/REQ${TransactionIDFromParams}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    error,
    data: { requisition, company } = { requisition: null, company: null },
    isFetching,
    refetch: refetchRequisition,
  } = useQuery(
    [requisitionActions.GET_REQUISITION_DETAILS, TransactionIDFromParams],
    () => getRequisitionDetails(TransactionIDFromParams),
    {
      onError: (err) => {
        console.log(err);
      },
      onSuccess: (data) => {},
    }
  );

  const getPendingItems = async (TransactionID) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/permits/get-by-transaction/${TransactionID}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        toast.error(response.message);
      } else {
        const {
          data: { items = [] },
        } = await response.json();
        if (isEmpty(items)) {
          return toast.error("No Items found");
        }

        populateTableFromItems(items);
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to get Items, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    getPendingItems(TransactionIDFromParams);
  });

  const updateAllPermit = async (payload) => {
    let response = await fetch(`${backendUrl}/api/permits/change-status-all`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updatePermitsMutation = useMutation(
    (payload) => updateAllPermit(payload),
    {
      onSuccess: ({ status, transactionIds }) => {
        toast.success(`Success`);

        //  Refetch Data
        getPendingItems(TransactionIDFromParams);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  /*   const approve = async () => {
    if (
      await ConfirmDialog({
        title: "Approve",
        description: "Are you sure, you want to approve",
      })
    ) {
      updatePermitsMutation.mutate({
        status: "Approved",
        transactionIds: [TransactionIDFromParams],
      });
    }
  };

  const disapprove = async () => {
    if (
      await ConfirmDialog({
        title: "Disapprove",
        description: "Are you sure, you want to disapprove",
      })
    ) {
      updatePermitsMutation.mutate({
        status: "Disapproved",
        transactionIds: [TransactionIDFromParams],
      });
    }
  }; */

  const openProformaInvoiceUrl = ({ thermalPrinter }) => {
    return !isEmpty(tableData) && tableData[0]?.Status !== "Quotation"
      ? `${backendUrl}/api/invoice/pdf/proforma-invoice/${TransactionIDFromParams}?thermalPrinter=${thermalPrinter}`
      : `${backendUrl}/api/invoice/pdf/quotation/${TransactionIDFromParams}?thermalPrinter=${thermalPrinter}`;
  };

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  const paymentFilter = useMemo(() => {
    /*   return selectedCustomer?.Cust_ID && selectedCustomer?.Cust_ID !== "000101"
      ? { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" }
      : { Remark: formik.values.ShipTo, Cust_ID: "" }; */
    return { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" };
  }, [selectedCustomer?.Cust_ID /* , formik.values.ShipTo */]);

  const customerPaymentsFilter = useDebounce(paymentFilter, 800);

  const fetchPayments = async ({ Cust_ID, Remark }) => {
    let response = await fetch(
      `${backendUrl}/api/customers/get-payments?${queryString.stringify({
        Cust_ID,
        /*     Remark, */
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.payments = [
      {
        value: "",
        label: "None",
      },
      ...data.payments.map((el) => {
        const requiresApproval = false;
        return {
          ...el,
          value: el.Trans_ID,
          label: `${el.Remark} -  ${currency(el.Credit, {
            symbol: currencySymbol,
          }).format()} - ${el?.Post_Time ? formatDate(el?.Post_Time) : ""} ${
            requiresApproval ? "- Requires Approval" : ""
          }`,
          requiresApproval,
        };
      }),
    ];

    return data;
  };

  const { data: paymentsData, ...paymentsDataQuery } = useQuery(
    ["CUSTOMER_PAYMENTS", customerPaymentsFilter],
    () => fetchPayments(customerPaymentsFilter),
    {
      keepPreviousData: false,
      enabled:
        generalSettings?.linkPaymentToInvoice &&
        !!customerPaymentsFilter.Cust_ID /*  || !!customerPaymentsFilter.Remark */,
    }
  );

  const handleLinkPayment = async (selected) => {
    const setShipTo = () => {
      //walk-In
      if (selectedCustomer?.Cust_ID === "000101") {
        formik.setFieldValue("ShipTo", selected.Remark);
      } else {
        formik.setFieldValue("ShipTo", customerFullName(selectedCustomer));
      }
      formik.setFieldValue("linkedPaymentID", selected?.value);
    };
    setShipTo();
  };

  const reject = async () => {
    if (
      await RejectDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
      })
    ) {
      refetchRequisition();
    }
  };

  const approveAndClose = async () => {
    if (
      await ApproveAndCloseDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
      })
    ) {
      refetchRequisition();
    }
  };

  const canPerformAction = (requisitionData) => {
    if (!requisition) return false;
    const pendingRequisition = last(requisitionData?.requisitiontracks);

    // By Department
    if (
      pendingRequisition?.sentToDepartment === authUser.Department &&
      pendingRequisition?.sentby !== authUser.Staff_ID &&
      pendingRequisition?.status === "Pending"
    ) {
      return true;
    }

    return (
      pendingRequisition &&
      pendingRequisition?.status === "Pending" &&
      pendingRequisition?.receivedby === authUser.Staff_ID
    );
  };

  const calculateServiceOtherValuesOnChange = ({
    index,
    Quantity,
    PriceSold,
    UnitCost,
    // Discount = 0,
    Warrant_Duration,
    discountPerItem = 0,
  }) => {
    const Discount = currency(discountPerItem).multiply(
      getNumberFromString(Warrant_Duration || Quantity)
    ).value;

    const Profit = currency(PriceSold)
      .subtract(UnitCost)
      .multiply(getNumberFromString(Warrant_Duration))
      .multiply(Quantity).value;

    const SubTotal = currency(PriceSold)
      // .subtract(Discount)
      .multiply(getNumberFromString(Warrant_Duration))
      .multiply(Quantity)
      .subtract(Discount).value;

    formik.setFieldValue(`tableDataInForm[${index}].Profit`, Profit);
    formik.setFieldValue(`tableDataInForm[${index}].SubTotal`, SubTotal);
    formik.setFieldValue(`tableDataInForm[${index}].Discount`, Discount);
  };

  const makeSale = () => {
    let cleanedTableData = tableData;
    if (
      formik.values.currency &&
      formik.values.currency !== generalSettings?.prevailingCurrency
    ) {
      cleanedTableData = tableData.map((el) =>
        convertToBaseCurrency({
          data: el,
          conversionAmount: formik.values.conversionAmount,
        })
      );
    }

    navigate("/sales-and-invoicing/create-invoice", {
      state: {
        jobItems: cleanedTableData,
      },
    });
  };

  const fetchItems = async (inputValue, callback) => {
    let response = await fetch(
      `${backendUrl}/api/items?page=1&Limit=50&q=${inputValue}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    if (data.items)
      callback(
        (data.items = data.items.map((el) => ({
          ...el,
          label: el.Item_Name,
          value: el.Item_Name,
          Warrant_Duration: el?.Warrant_Duration ? el.Warrant_Duration : 1,
        })))
      );
  };

  const debouncedFetchItems = debounce(fetchItems, 500);

  const loadOptions = (inputValue, callback) => {
    debouncedFetchItems(inputValue, callback);
  };

  const jobMileStones = useMemo(() => {
    if (requisition?.job) {
      const milestones = requisition?.job.milestones
        ? JSON.parse(requisition?.job.milestones)
        : [];
      return milestones.map((el) => ({
        ...el,
        label: el.milestone,
        value: el.milestone,
      }));
    }

    return [];
  }, [requisition?.job]);

  useEffect(() => {
    if (Number(discount) > 0) {
      formik.setFieldValue("generalDiscountPercentage", 0);
      formik.setFieldValue("generalDiscount", 0);
      return;
    }

    if (formik.values.generalDiscountType === "Percentage") {
      formik.setFieldValue(
        "generalDiscount",
        currency(subTotal)
          .subtract(discount)
          .multiply(formik.values.generalDiscountPercentage)
          .divide(100)
      );
    }
  }, [formik.values.generalDiscountPercentage, subTotal, discount]);

  return (
    <main className="create-invoice">
      <PageHeader
        name={`Edit Pending Service Invoice`}
        description={`Edit a Pending Service Invoice For Approval`}
        icon={<CreateInvoiceIcon />}
      />
      <div className="p-3 content">
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="d-md-flex content-holder rounded">
              <section className="item-details">
                <div>
                  <header className="d-flex gap-3 justify-content-between">
                    <h1 className="h5">
                      {" "}
                      {requisition?.title}{" "}
                      {requisition?.jobNumber
                        ? ` / ${requisition?.jobNumber}-${
                            requisition?.job?.title
                          }`
                        : ``}
                    </h1>
                    <ApprovalBadge
                      text={requisition?.status}
                      className="approval"
                      bg={resolveApprovalBadgeBg(requisition?.status)}
                    />
                  </header>
                  <div className="actions">
                    <div>
                      <h2>Item Details</h2>
                      <p>Add items in to the invoice table. </p>
                    </div>
                    <div className="d-flex gap-3">
                      <Form.Group>
                        <Select
                          classNamePrefix="form-select"
                          placeholder="Select Currency"
                          isSearchable={false}
                          options={currenciesOptions}
                          value={currenciesOptions.find(
                            (el) => el.value === formik.values.currency
                          )}
                          onChange={({ value }) =>
                            formik.setFieldValue("currency", value)
                          }
                        />
                      </Form.Group>
                      {formik.values.currency &&
                      formik.values.currency !==
                        generalSettings?.prevailingCurrency ? (
                        <Form.Group>
                          <CurrencyCustomInput
                            currencySymbol={"Ex. Rate"}
                            name="conversionAmount"
                            value={formik.values.conversionAmount}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            placeholder="0.00"
                          />
                        </Form.Group>
                      ) : null}

                      {canPerformAction(requisition) && (
                        <Button
                          onClick={() => setShowItemSelectorModal(true)}
                          variant="outline-primary"
                          className="text-nowrap"
                        >
                          + Add Item
                        </Button>
                      )}
                      {/* 
                      <Dropdown className="">
                        <Dropdown.Toggle>View Invoice</Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-with-icons">
                          <Dropdown.Item
                            href={openProformaInvoiceUrl({
                              thermalPrinter: false,
                            })}
                            target="blank"
                          >
                            <BookIcon />
                            A4 Size Printer
                          </Dropdown.Item>
                          <Dropdown.Item
                            href={openProformaInvoiceUrl({
                              thermalPrinter: true,
                            })}
                            target="blank"
                          >
                            <BookIcon />
                            Thermal Printer
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}

                      {formik.values?.tableDataInForm ? (
                        <>
                          {formik.values?.tableDataInForm[0].Status ===
                          "Invoiced" ? (
                            <Button
                              onClick={() =>
                                navigate(
                                  `/inventory-for-sales/manage-transaction`,
                                  {
                                    state: {
                                      TransactionID: TransactionIDFromParams,
                                    },
                                  }
                                )
                              }
                              variant="primary"
                              className="text-nowrap"
                            >
                              View Invoice
                            </Button>
                          ) : (
                            <>
                              {requisition?.status === "Approved & Closed" && (
                                <Button
                                  onClick={() => makeSale()}
                                  variant="primary"
                                  className="text-nowrap"
                                >
                                  Final Invoice Review
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="selected-data-area">
                    <div className="table-holder">
                      <Table
                        responsive
                        borderless
                        hover
                        striped
                        className="product-table  text-nowrap"
                      >
                        <thead>
                          <tr>
                            <th />
                            {/*  <th>Size/Desc</th> */}
                            <th>Service Name</th>
                            <th>Number of Days</th>
                            <th title="Price Sold">Cost</th>
                            <th>Quantity</th>
                            <th>Discount</th>
                            <th>Subtotal</th>
                            {/* 
                            <th>Item Code</th>
                            <th>Product name</th>
                            <th>Unit Price</th>
                            <th>Profit</th>
                            <th>...</th>
                            <th>...</th>
                            <th>Overwrite officer</th>
                            <th>Cost</th>
                            <th>Type</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name="tableDataInForm"
                            render={(arrayHelpers) => (
                              <>
                                {formik.values?.tableDataInForm &&
                                  formik.values.tableDataInForm.map(
                                    (el, index) => (
                                      <tr
                                        key={index}
                                        // onClick={(e) => handleRowClick(e, index)}
                                        className="p-cursor"
                                      >
                                        <td>
                                          <Button
                                            variant=""
                                            type="button"
                                            onClick={() =>
                                              handleRemoveItem(index)
                                            }
                                            disabled={
                                              !canPerformAction(requisition)
                                            }
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </td>
                                        {/* <td title={el.Serial_Number}>
                              {truncate(el.Serial_Number)}
                            </td> */}
                                        <td className="col-5">
                                          {/* <Form.Control
                                            name={`tableDataInForm[${index}].Item_Name`}
                                            value={
                                              formik.values.tableDataInForm[
                                                index
                                              ].Item_Name
                                            }
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => {
                                              if (e.keyCode === 13) {
                                                e.preventDefault();
                                                arrayHelpers.push(
                                                  initialServiceItem
                                                );
                                              }
                                            }}
                                            as={"textarea"}
                                            rows={2}
                                          /> */}

                                          <AsyncCreatableSelect
                                            classNamePrefix="form-select"
                                            menuPosition="fixed"
                                            value={{
                                              label:
                                                formik.values.tableDataInForm[
                                                  index
                                                ].Item_Name,
                                              value:
                                                formik.values.tableDataInForm[
                                                  index
                                                ].Item_Name,
                                            }}
                                            onChange={(selected) => {
                                              // console.log(selected, index);
                                              if (selected.__isNew__) {
                                                const newItem = initialServiceItem();
                                                handleEditItemByIndex(
                                                  {
                                                    ...newItem,
                                                    Item_Name: selected.value,
                                                  },
                                                  index
                                                );
                                              } else {
                                                handleEditItemByIndex(
                                                  {
                                                    ...selected,
                                                    Quantity: 1,
                                                    PriceSold:
                                                      selected.UnitPrice,
                                                    Warrant_Duration: "",
                                                  },
                                                  index
                                                );
                                              }

                                              /*  formik.setFieldValue(
                                                `tableDataInForm[${index}].Item_Name`,
                                                selected?.value
                                              );
                                              formik.setFieldValue(
                                                `tableDataInForm[${index}].Bar_Code`,
                                                selected?.Bar_Code
                                              ); */
                                            }}
                                            defaultOptions={true}
                                            isSearchable={true}
                                            isClearable={true}
                                            loadOptions={loadOptions}
                                          />
                                        </td>
                                        {/*  <td>
                                          <Form.Control
                                            name={`tableDataInForm[${index}].Warrant_Duration`}
                                            value={
                                              formik.values.tableDataInForm[
                                                index
                                              ].Warrant_Duration
                                            }
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => {
                                              if (e.keyCode === 13) {
                                                e.preventDefault();
                                                arrayHelpers.push(
                                                  initialServiceItem()
                                                );
                                              }
                                            }}
                                            as={"textarea"}
                                            rows={3}
                                          />
                                        </td> */}

                                        <td>
                                          <NumberCustomInput
                                            name={`tableDataInForm[${index}].Warrant_Duration`}
                                            value={
                                              formik.values.tableDataInForm[
                                                index
                                              ].Warrant_Duration
                                            }
                                            onValueChange={(value, name) => {
                                              value =
                                                typeof value === "undefined"
                                                  ? ""
                                                  : value;
                                              formik.setFieldValue(name, value);
                                              calculateServiceOtherValuesOnChange(
                                                {
                                                  index,
                                                  Quantity:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .Quantity,
                                                  PriceSold:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .PriceSold,
                                                  UnitCost:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .UnitCost,
                                                  discountPerItem:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .discountPerItem,
                                                  Warrant_Duration: value,
                                                }
                                              );
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.keyCode === 13) {
                                                e.preventDefault();
                                                arrayHelpers.push(
                                                  initialServiceItem()
                                                );
                                              }
                                            }}
                                          />
                                        </td>

                                        <td>
                                          <CurrencyCustomInput
                                            currencySymbol={""}
                                            name={`tableDataInForm[${index}].PriceSold`}
                                            value={
                                              formik.values.tableDataInForm[
                                                index
                                              ].PriceSold
                                            }
                                            onValueChange={(value, name) => {
                                              formik.setFieldValue(name, value);
                                              calculateServiceOtherValuesOnChange(
                                                {
                                                  index,
                                                  Quantity:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .Quantity,
                                                  PriceSold: value,
                                                  UnitCost:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .UnitCost,
                                                  discountPerItem:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .discountPerItem,
                                                  Warrant_Duration:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .Warrant_Duration,
                                                }
                                              );
                                            }}
                                            placeholder="0.00"
                                            onKeyDown={(e) => {
                                              if (e.keyCode === 13) {
                                                e.preventDefault();
                                                arrayHelpers.push(
                                                  initialServiceItem
                                                );
                                              }
                                            }}
                                          />
                                        </td>
                                        <td>
                                          <NumberCustomInput
                                            name={`tableDataInForm[${index}].Quantity`}
                                            value={
                                              formik.values.tableDataInForm[
                                                index
                                              ].Quantity
                                            }
                                            onValueChange={(value, name) => {
                                              formik.setFieldValue(name, value);
                                              calculateServiceOtherValuesOnChange(
                                                {
                                                  index,
                                                  Quantity: value,
                                                  PriceSold:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .PriceSold,
                                                  UnitCost:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .UnitCost,
                                                  discountPerItem:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .discountPerItem,
                                                  Warrant_Duration:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .Warrant_Duration,
                                                }
                                              );
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.keyCode === 13) {
                                                e.preventDefault();
                                                arrayHelpers.push(
                                                  initialServiceItem
                                                );
                                              }
                                            }}
                                          />
                                        </td>

                                        <td>
                                          <CurrencyCustomInput
                                            currencySymbol={""}
                                            name={`tableDataInForm[${index}].discountPerItem`}
                                            value={
                                              formik.values.tableDataInForm[
                                                index
                                              ].discountPerItem
                                            }
                                            onValueChange={(value, name) => {
                                              formik.setFieldValue(name, value);
                                              calculateServiceOtherValuesOnChange(
                                                {
                                                  index,
                                                  Quantity:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .Quantity,
                                                  PriceSold:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .PriceSold,
                                                  UnitCost:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .UnitCost,
                                                  discountPerItem: value,
                                                  Warrant_Duration:
                                                    formik.values
                                                      .tableDataInForm[index]
                                                      .Warrant_Duration,
                                                }
                                              );
                                            }}
                                            placeholder="0.00"
                                            onKeyDown={(e) => {
                                              if (e.keyCode === 13) {
                                                e.preventDefault();
                                                arrayHelpers.push(
                                                  initialServiceItem
                                                );
                                              }
                                            }}
                                          />
                                        </td>

                                        <td>
                                          {currency(el.SubTotal, {
                                            symbol: "",
                                          }).format()}
                                        </td>
                                        {/*   <td>
                                      {currency(el.Discount, {
                                        symbol: "",
                                      }).format()}
                                    </td> */}
                                        {/*  <td>{el.Bar_Code}</td>
                            <td>{el.Product_Name || el.ProductName}</td>
                            <td>
                              {currency(el.UnitPrice, {
                                symbol: "",
                              }).format()}
                            </td>
                            <td>
                              {currency(el.Profit, { symbol: "" }).format()}
                            </td>
                            <td>{el.Warranty}</td>
                            <td>{el.Warrant_Duration}</td>
                            <td>{"..."}</td>
                            <td>
                              {currency(Number(el.UnitCost), { symbol: "" })
                                .multiply(
                                  convertQuantity(
                                    el.Quantity,
                                    el.Serial_Number,
                                    el.saleType
                                  )
                                )
                                .format()}
                            </td>
                            <td>{el.Item_Type || "..."}</td> */}
                                      </tr>
                                    )
                                  )}
                                {/*  {formik.values.terms.map((el, index) => (
                            <div key={index} className="d-flex gap-2  mb-3">
                              <Form.Control
                                name={`terms[${index}].text`}
                                placeholder="Enter Terms"
                                value={formik.values.terms[index].text}
                                onChange={formik.handleChange}
                                as={"textarea"}
                              />
                              <button
                                type="button"
                                title="Remove"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                ✖
                              </button>
                            </div>
                          ))} */}
                                {/*  <div className="d-flex justify-content-end px-5 mt-3">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary text-nowrap"
                              onClick={() => arrayHelpers.push({ text: "" })}
                            >
                              + Add
                            </button>
                          </div> */}
                              </>
                            )}
                          />
                        </tbody>
                      </Table>
                    </div>

                    {isEmpty(tableData) ? (
                      <div className="no-item my-4">
                        <div className="info">
                          <NoSelectedItemIcon />
                          <h2 className="mb-2">Haven't selected an item yet</h2>
                          <p>
                            You can click +Add Item Button to add an item to the
                            table.
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex justify-content-end total-info">
                  <table className="table table-borderless balance">
                    <tbody>
                      <tr>
                        <td>Total Amount</td>
                        <td>
                          {currency(subTotal, {
                            symbol: currencySymbol,
                          })
                            .add(chargesAfterTax)
                            .format()}
                        </td>
                      </tr>

                      <tr>
                        <td>Discount</td>
                        <td>
                          {currency(discount, {
                            symbol: currencySymbol,
                          })
                            .add(formik.values.generalDiscount)
                            .format()}
                        </td>
                      </tr>

                      <tr>
                        <td>Subtotal</td>
                        <td>
                          {currency(subTotal, {
                            symbol: currencySymbol,
                          })
                            .add(chargesAfterTax)
                            .subtract(discount)
                            .subtract(formik.values.generalDiscount)
                            .format()}
                        </td>
                      </tr>

                      {formik.values?.taxType !== "None" && (
                        <tr>
                          <td>{formik.values.taxType}</td>
                          <td>
                            {currency(taxValue, {
                              symbol: currencySymbol,
                            }).format()}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td>Amount Due</td>
                        <td>
                          {currency(amountDue, {
                            symbol: currencySymbol,
                          }).format()}
                        </td>
                      </tr>

                      {/*    <tr>
                        <td>Balance</td>
                        <td>
                          {currency(balance, {
                            symbol: currencySymbol,
                          }).format()}
                        </td>
                      </tr>

                      <tr>
                        <td>Total</td>
                        <td>
                          {currency(grandTotal, {
                            symbol: currencySymbol,
                          }).format()}
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>

                {canPerformAction(requisition) ? (
                  <div
                    className="border-top py-4 d-flex mt-4 d-flex justify-content-between d-flex gap-3 	bg-white"
                    style={{ zIndex: "10" }}
                  >
                    <Button
                      onClick={() => reject()}
                      variant="danger"
                      className="text-white px-3 reject"
                    >
                      Reject
                    </Button>

                    <div className="proceed-actions d-flex gap-3 ">
                      <Button
                        className="btn btn-white border bg-white"
                        onClick={() => setShowApproveAndSendModal(true)}
                      >
                        Approve & Sent
                      </Button>
                      <Button
                        className="approve-and-close"
                        onClick={() => approveAndClose()}
                        variant="primary"
                      >
                        Approve & Close
                      </Button>
                    </div>
                  </div>
                ) : null}

                <div className="d-flex justify-content-end py-4 bg-white z-10">
                  <div className="col-md-3 px-0 mx-0 d-flex justify-content-end gap-3">
                    {formik.values?.tableDataInForm ? (
                      <>
                        {formik.values?.tableDataInForm[0].Status ===
                        "Invoiced" ? (
                          <Button
                            onClick={() =>
                              navigate(
                                `/inventory-for-sales/manage-transaction`,
                                {
                                  state: {
                                    TransactionID: TransactionIDFromParams,
                                  },
                                }
                              )
                            }
                            variant="primary"
                            className="text-nowrap"
                          >
                            View Invoice
                          </Button>
                        ) : (
                          <>
                            {requisition?.status === "Approved & Closed" && (
                              <Button
                                onClick={() => makeSale()}
                                variant="primary"
                                className="text-nowrap"
                              >
                                Final Invoice Review
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              </section>
              <section className="customer">
                <h2>Customer/Client</h2>

                <div className="d-flex justify-content-between">
                  <div className="avatar">
                    <UserSolidIcon />
                  </div>
                  {selectedCustomer ? (
                    <div className="customer-actions d-flex justify-content-between flex-grow-1">
                      <div>
                        <h3>{selectedCustomer?.LastName}</h3>
                        <p>{selectedCustomer.Cust_ID}</p>
                      </div>

                      <div>
                        {!formik.values.pendingTransaction && (
                          <Dropdown style={{ margin: 0 }}>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-light-blue text-primary"
                              bsPrefix="change"
                            >
                              Change
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className=""
                            >
                              <Dropdown.Item
                                as="button"
                                type="button"
                                onClick={() =>
                                  setShowCustomerSelectorModal(true)
                                }
                              >
                                Select Customer
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                type="button"
                                onClick={() =>
                                  setShowCreateNewCustomerModal(true)
                                }
                              >
                                Create New Customer
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="customer-actions flex-grow-1">
                      <h3>No customer selected</h3>
                      <p>Select customer or create new customer.</p>

                      <div className="d-grid mt-4">
                        <Button
                          onClick={() => setShowCustomerSelectorModal(true)}
                          variant="outline-primary"
                        >
                          Select Customer
                        </Button>
                        <Button
                          onClick={() => setShowCreateNewCustomerModal(true)}
                          variant="outline-primary"
                        >
                          + Create New Customer
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <section>
                  <Form.Group className="form-mb ">
                    <Form.Label>Milestone</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      options={jobMileStones}
                      value={jobMileStones.find(
                        (el) => el.value === formik.values.milestone
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("milestone", value)
                      }
                      //  isSearchable
                    />
                  </Form.Group>
                  <Form.Group className="form-mb ">
                    <Form.Label className="mb-1 text-nowrap fw-bold">
                      Date
                    </Form.Label>

                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      dateFormat="MMM DD, YYYY"
                      name="salesDate"
                      inputProps={{
                        className: `date-input form-control ${
                          formik.touched.salesDate && !!formik.errors.salesDate
                            ? "is-invalid"
                            : ""
                        }`,
                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.salesDate}
                      onChange={(date) => {
                        formik.setFieldValue("salesDate", date, true);
                      }}
                      onBlur={() => formik.setFieldTouched("salesDate", true)}
                    />
                  </Form.Group>{" "}
                </section>
                {/* <Form.Group className="form-mb align-items-center">
                <Form.Label className="mb-1 text-nowrap fw-bold">
                  Ship To.
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ship To"
                  name="ShipTo"
                  value={formik.values.ShipTo}
                  onChange={formik.handleChange}
                />
              </Form.Group> */}

                {/*   {generalSettings?.linkPaymentToInvoice && (
                <Form.Group className="form-mb align-items-center">
                  <Form.Label className="mb-1 text-nowrap fw-bold">
                    Link Payment to Invoice
                  </Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    options={paymentsData?.payments}
                    value={paymentsData?.payments.find(
                      (el) => el.value === formik.values.linkedPaymentID
                    )}
                    onChange={(selected) => {
                      handleLinkPayment(selected);
                    }}
                    isClearable={true}
                    isLoading={paymentsDataQuery.isFetching}
                  />
                </Form.Group>
              )} */}
                <hr />
                <section className="date">
                  <h2>Invoice Details</h2>

                  <Row className="form-mb">
                    {/*  <Form.Group as={Col}>
                    <Form.Label>Sales Date</Form.Label>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      dateFormat="MMM DD, YYYY"
                      name="salesDate"
                      inputProps={{
                        className: `date-input form-control ${
                          formik.touched.salesDate &&
                          !!formik.errors.salesDate
                            ? "is-invalid"
                            : ""
                        }`,
                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.salesDate}
                      onChange={(date) => {
                        formik.setFieldValue("salesDate", date, true);
                      }}
                      onBlur={() => formik.setFieldTouched("salesDate", true)}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Invoice Cat </Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Select"
                      isSearchable={false}
                      options={data.invoiceCat}
                      value={data.invoiceCat.find(
                        (el) => el.value === formik.values.invoiceCat
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("invoiceCat", value)
                      }
                      onBlur={() =>
                        formik.setFieldTouched("invoiceCat", true)
                      }
                      className={
                        formik.touched.invoiceCat &&
                        !!formik.errors.invoiceCat
                          ? "is-invalid"
                          : ""
                      }
                    />
                    {formik.touched.invoiceCat && formik.errors.invoiceCat ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.invoiceCat}
                      </span>
                    ) : null}
                  </Form.Group> */}

                    <Form.Group as={Col}>
                      <Form.Label>Bank Account on Invoice</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select"
                        isSearchable={false}
                        options={data.banks}
                        value={data.banks?.find(
                          (el) => el.value === formik.values.bankOnInvoice
                        )}
                        onChange={(selected) =>
                          formik.setFieldValue("bankOnInvoice", selected?.value)
                        }
                        isClearable
                      />
                    </Form.Group>
                  </Row>
                </section>

                <section className="date">
                  <h2>Payment Details</h2>
                  {/* 
                <div className="row mb-3">
                  <Form.Group className="col-12">
                    <Form.Label>Payment Type</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPlacement="top"
                      placeholder="Choose method"
                      isSearchable={false}
                      value={paymentMethod.find(
                        (el) => el.value === formik.values.PaymentType
                      )}
                      options={paymentMethod}
                      onChange={({ value }) => handlePaymentMethod(value)}
                      getOptionLabel={(el) => (
                        <div className="label-with-icon d-flex gap-2 align-items-center">
                          <span>{el.icon}</span>{" "}
                          <span className="fw-5">{el.label}</span>
                        </div>
                      )}
                      //  menuIsOpen={true}
                    />
                  </Form.Group>
                </div> */}

                  <section>
                    <Row
                      style={
                        Number(discount) > 0 ? { pointerEvents: "none" } : {}
                      }
                    >
                      <Form.Group className="col-md-4 mb-3 pb-2">
                        <Form.Label className="mb-1">Discount Type</Form.Label>
                        <Select
                          classNamePrefix={"form-select"}
                          options={amountTypes}
                          value={amountTypes.find(
                            (el) =>
                              el.value === formik.values.generalDiscountType
                          )}
                          onChange={(selected) => {
                            formik.setFieldValue(
                              "generalDiscountType",
                              selected.value
                            );
                            if (selected.value === "None") {
                              formik.setFieldValue(
                                "generalDiscountPercentage",
                                0
                              );
                              formik.setFieldValue("generalDiscount", 0);
                            }
                          }}
                          isDisabled={Number(discount) > 0}
                        />
                      </Form.Group>

                      {formik.values.generalDiscountType === "Percentage" && (
                        <Form.Group className="col-md-3 mb-3 pb-2">
                          <Form.Label className="mb-1">Discount (%)</Form.Label>
                          <Form.Control
                            type="number"
                            name="generalDiscountPercentage"
                            value={formik.values.generalDiscountPercentage}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      )}

                      <Form.Group className="col mb-3 pb-2">
                        <Form.Label className="mb-1"> Discount</Form.Label>
                        <CurrencyCustomInput
                          name="generalDiscount"
                          placeholder="0.00"
                          value={formik.values.generalDiscount}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                        />
                      </Form.Group>
                    </Row>
                  </section>

                  <section>
                    <div className="row form-mb">
                      <Form.Group
                        className="col-6"
                        controlId="formGridPassword"
                      >
                        <Form.Label>Tax </Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          placeholder="Select"
                          isSearchable={false}
                          value={taxOptions.find(
                            (el) => el.value === formik.values.taxType
                          )}
                          options={taxOptions}
                          onChange={({ value }) =>
                            formik.setFieldValue("taxType", value)
                          }
                        />
                      </Form.Group>

                      <Form.Group className="col-6">
                        <Form.Label>
                          {formik.values.taxType &&
                          formik.values.taxType !== "None"
                            ? formik.values.taxType
                            : "VAT"}
                        </Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="tax"
                          value={taxValue}
                          onValueChange={(value, name) => {
                            //   formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                      </Form.Group>
                    </div>
                  </section>

                  <section>
                    <div className="row">
                      <Form.Group className="col-6 mt-2 form-mb">
                        <Form.Label>Shipping Cost</Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="shippingCost"
                          value={formik.values.shippingCost}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                      </Form.Group>

                      <Form.Group className="col-6 mt-2 form-mb">
                        <Form.Label>Due In (days)</Form.Label>
                        <NumberCustomInput
                          name="dueIn"
                          value={formik.values.dueIn}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </section>

                  <section>
                    <Form.Group className=" mt-2 form-mb">
                      <Form.Label>
                        Clearing charges, Custom duty and Other Charges
                      </Form.Label>
                      <CurrencyCustomInput
                        currencySymbol={currencySymbol}
                        name="otherCharges"
                        value={formik.values.otherCharges}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        placeholder="0.00"
                      />
                    </Form.Group>
                  </section>

                  <section>
                    <Form.Group className=" mt-2 form-mb">
                      <Form.Label>Terms</Form.Label>
                      <FieldArray
                        name="terms"
                        render={(arrayHelpers) => (
                          <>
                            {formik.values.terms.map((el, index) => (
                              <div key={index} className="d-flex gap-2  mb-3">
                                <Form.Control
                                  name={`terms[${index}].text`}
                                  placeholder="Enter Terms"
                                  value={formik.values.terms[index].text}
                                  onChange={formik.handleChange}
                                  as={"textarea"}
                                />
                                <button
                                  type="button"
                                  title="Remove"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  ✖
                                </button>
                              </div>
                            ))}
                            <div className="d-flex justify-content-end px-5 mt-3">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary text-nowrap"
                                onClick={() => arrayHelpers.push({ text: "" })}
                              >
                                + Add
                              </button>
                            </div>
                          </>
                        )}
                      />
                    </Form.Group>

                    <Form.Group className=" mt-2 form-mb">
                      <Form.Label>Remark</Form.Label>
                      <Form.Control
                        name={`remark`}
                        placeholder="Enter Remark"
                        value={formik.values.remark}
                        onChange={formik.handleChange}
                        as={"textarea"}
                      />
                    </Form.Group>
                  </section>
                </section>

                {canPerformAction(requisition) && (
                  <section className="buttons">
                    <Button
                      type="button"
                      variant="outline-primary"
                      // className="border-0"
                      onClick={() => discard()}
                    >
                      Discard
                    </Button>
                    <Button type="submit" variant="primary">
                      Save Changes
                    </Button>
                  </section>
                )}
              </section>
            </div>
          </Form>
        </FormikProvider>
      </div>

      {/*   Modals */}

      {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          selectedCustomer={selectedCustomer}
          withCredit={true}
        />
      )}

      {showItemSelectorModal && (
        <AddRodItemModal
          showItemSelectorModal={showItemSelectorModal}
          setShowItemSelectorModal={setShowItemSelectorModal}
          handleAddItem={handleAddItem}
          saleTypes={saleTypes}
          lockedTableData={lockedTableData}
          selectedCustomer={selectedCustomer}
          conversionAmount={formik.values.conversionAmount}
          currencySymbol={currencySymbol}
          currencyText={formik.values.currency}
        />
      )}

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {editedItemIndex !== null && (
        <EditIronRodItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItemToEdit={{
            ...tableData[editedItemIndex],
            //  quantityInStock: tableData[editedItemIndex].Quantity,
          }}
          saleTypes={saleTypes}
          conversionAmount={formik.values.conversionAmount}
          currencySymbol={currencySymbol}
          currencyText={formik.values.currency}
        />
      )}

      {showPermitModal && (
        <PermitModal
          setShowPermitModal={setShowPermitModal}
          handleSelectedPermit={handleSelectedPermit}
          batchData={true}
        />
      )}

      <ModalLoader
        show={
          createRodPermitMutation.isLoading ||
          createRodInvoiceMutation.isLoading ||
          loadingPrint ||
          isLoading ||
          updatePermitsMutation.isLoading
        }
      />

      {showApproveAndSendModal && (
        <ApproveAndSendModal
          requestid={`REQ${TransactionIDFromParams}`}
          setShowApproveAndSendModal={setShowApproveAndSendModal}
          refetchRequisition={refetchRequisition}
        />
      )}
    </main>
  );
}
